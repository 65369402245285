import {
	Collapse,
	Divider,
	HStack,
	Icon,
	IconButton,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { IoMdMail, IoMdMailOpen } from 'react-icons/io'
import { sectionDetailedTrack } from '../../../containers/repository/repo.utils'
import { useUpdateReadStatus } from '../../../hooks/broadcast.hooks'
import { GA_CATEGORY_FILES } from './../../../containers/repository/repo.utils'
import { convertPound, convertUnicode } from '../../../utils/linkInText.utils'

const BroadCastItem = ({ msg, index, refresh, refreshMsg }) => {
	const { mutate } = useUpdateReadStatus()
	const { isOpen: expand, onToggle } = useDisclosure()

	const onExpand = d => {
		mutate(
			{
				broadcast_id: d.broadcast_id,
				broadcast_flag_read: 1,
				flag: 1,
			},
			{
				onSuccess: async res => {
					refresh()
					setTimeout(() => {
						refreshMsg()
					}, 500)
				},
			}
		)
	}

	return (
		<HStack
			justifyContent='space-between'
			boxShadow='md'
			key={index}
			borderRadius='0'
			w='full'
			px={2}
		>
			<IconButton
				my={2}
				onClick={() => {
					sectionDetailedTrack({
						category: GA_CATEGORY_FILES,
						action: 'Broadcast Items',
						label: 'Expand Message',
					})
					if (msg.broadcast_flag_read === 0) {
						onExpand(msg)
					}
				}}
				icon={
					<Icon
						as={msg.broadcast_flag_read ? IoMdMailOpen : IoMdMail}
						h={6}
						w={6}
					/>
				}
				color={localStorage.getItem('color')}
				_focus={{
					color: localStorage.getItem('color'),
				}}
				_hover={{
					color: 'white',
					bg: localStorage.getItem('color'),
				}}
				_active={{
					color: 'white',
					bg: localStorage.getItem('color'),
				}}
			/>
			<VStack
				flex={1}
				p={2}
				alignItems='flex-start'
				wordBreak='break-all'
				overflow='hidden'
			>
				<Text
					fontSize='sm'
					alignSelf='flex-start'
					m={0}
					p={0}
					color='brand.800'
					textTransform='capitalize'
				>
					{msg.broadcast_subject}
				</Text>
				<Divider />
				<Collapse
					alignSelf='flex-start'
					startingHeight={20}
					in={expand}
					w='100%'
				>
					<Text
						alignSelf='flex-start'
						textAlign='left'
						style={{ margin: '0 !important' }}
						fontSize='sm'
						dangerouslySetInnerHTML={{
							__html: convertPound(
								convertUnicode(unescape(msg.broadcast_content))
							),
						}}
					/>
				</Collapse>
				<HStack justifyContent='space-between' alignItems='center' w='full'>
					<Text
						alignSelf='flex-start'
						cursor='pointer'
						fontSize='sm'
						display='inline'
						color='blue.300'
						ml={1}
						onClick={() => {
							sectionDetailedTrack({
								category: GA_CATEGORY_FILES,
								action: 'Broadcast Items',
								label: 'Toggle Read More',
							})
							onToggle()
						}}
					>
						{!expand ? 'read more' : 'read less'}
					</Text>
					<Text fontSize='10px' color='brand.800' alignSelf='flex-end'>
						{format(
							new Date(msg.log_datetime.split(' ').join('T')),
							'dd MMM yyyy, HH:mm'
						)}
					</Text>
				</HStack>
			</VStack>
		</HStack>
	)
}

export default BroadCastItem
