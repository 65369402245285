/* eslint-disable react-hooks/exhaustive-deps */
import {
	Badge,
	Box,
	Icon,
	IconButton,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Text,
	VStack,
	HStack,
	Switch,
	FormLabel,
	FormControl,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdNotifications } from 'react-icons/md'
// import {
//   sectionDetailedTrack,
//   dataLayerTagManager,
// } from '../../../containers/repository/repo.utils'
import { useSharedContext } from '../../../context/shared.context'
import { useLanguageContext } from '../../../context/locale.context'
import {
	useBroadcastMessagecount,
	useGetBroadcastMessage,
} from '../../../hooks/broadcast.hooks'
import { sharedConst } from '../../../utils/action.constant'
import BroadCastItem from './broadcast-item'
import { GA_CATEGORY_FILES } from './../../../containers/repository/repo.utils'

const Broadcast = props => {
	const { mutate, isLoading } = useGetBroadcastMessage()
	const { mutate: messageCountMutate } = useBroadcastMessagecount()
	const [isUnRead, setIsUnRead] = useState(true)
	const [notifications, setNotifications] = useState([])
	const [broadcastList, setBroadcastList] = useState([])
	const firstFieldRef = React.useRef(null)
	const { state, dispatch } = useSharedContext()
	const { refreshBroadCast } = state || {}

	const [msgCount, setMsgCount] = useState([])
	const {
		state: { locale },
	} = useLanguageContext()

	const reloadCount = () => {
		messageCountMutate(
			{},
			{
				onSuccess: async res => {
					const {
						data: { status, response },
					} = res
					if (status === 200) {
						setMsgCount(response[0])
					}
				},
			}
		)
	}

	useEffect(() => {
		if (!!refreshBroadCast) {
			reloadCount()
			dispatch({
				type: sharedConst.REFRESH_BROADCAST_COUNT,
				refreshBroadCast: false,
			})
		}
	}, [refreshBroadCast])

	const getFilter = list => {
		return list.filter(e => (!!isUnRead ? !e.broadcast_flag_read : true))
	}

	const refresh = () => {
		mutate(
			{
				flag: 0,
			},
			{
				onSuccess: async res => {
					setBroadcastList(res)
					let data = getFilter(res)
					setNotifications(data)
					dispatch({
						type: sharedConst.REFRESH_BROADCAST_COUNT,
						refreshBroadCast: true,
					})
				},
				onError: async err => {
					console.log('Error', err)
				},
			}
		)
		reloadCount()
	}

	useEffect(() => {
		let data
		if (!!broadcastList && broadcastList.length) {
			if (!!isUnRead) {
				data = broadcastList.filter(e => !e.broadcast_flag_read)
			} else {
				data = broadcastList
			}
			setNotifications(data)
		}
	}, [isUnRead, broadcastList])

	useEffect(() => {
		setIsUnRead(true)
		refresh()
	}, [])

	return (
		<Popover
			preventOverflow
			initialFocusRef={firstFieldRef}
			placement='bottom-start'
		>
			<PopoverTrigger>
				<Box position='relative'>
					<IconButton
						onClick={() => {
							// sectionDetailedTrack({
							//   category: GA_CATEGORY_FILES,
							//   action: 'Broadcast',
							//   label: 'Refresh',
							// })
							// dataLayerTagManager('button_click', {
							//   viewName: 'Navbar',
							//   buttonName: 'Broadcast Notification',
							// })
							refresh()
						}}
						variant='unstyled'
						aria-label='notifications'
						icon={<Icon as={MdNotifications} w={8} h={8} color='brand.800' />}
					/>
					{!!msgCount.unread_count ? (
						<Badge
							position='absolute'
							top='2px'
							right='5px'
							borderRadius='full'
							size='sm'
						>
							<Text fontSize='10px'>{msgCount.unread_count}</Text>
						</Badge>
					) : null}
				</Box>
			</PopoverTrigger>
			<PopoverContent
				minW='400px'
				strategy='absolute'
				borderRadius='4'
				boxShadow='md'
				_focus={{
					border: 'none',
				}}
			>
				<PopoverArrow />
				<PopoverHeader>
					<HStack justifyContent='space-between' w='full'>
						<Box flex={1}>{locale['Broadcast Messages']}</Box>
						<FormControl display='flex' alignItems='center' w='auto' pr='20px'>
							<FormLabel htmlFor='onlyRead' mb='0'>
								{isUnRead ? locale['Unread'] : locale['All']}
							</FormLabel>
							<Switch
								colorScheme={localStorage.getItem('color')}
								id='onlyRead'
								value={isUnRead}
								onChange={e => {
									setIsUnRead(!isUnRead)
								}}
							/>
						</FormControl>
					</HStack>
				</PopoverHeader>
				<PopoverCloseButton />
				<PopoverBody p={0} m={0}>
					<VStack
						bg='white'
						maxH='350px'
						// overflowY="hidden"
						overflowY='scroll'
						w='full'
						p={2}
					>
						{notifications
							.sort((d, c) => d.broadcast_flag_read - c.broadcast_flag_read)
							.map((n, i) => (
								<BroadCastItem
									msg={n}
									refresh={reloadCount}
									refreshMsg={refresh}
									index={i}
									key={i}
								/>
							))}
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}

export default Broadcast
