import React, { useState } from 'react'
import { Box, VStack, Text, Spinner, Center, Heading } from '@chakra-ui/react'
import { useLanguageContext } from '../../../context/locale.context'
import { useQuery } from 'react-query'
import { getCollectionV2QueryKey } from './../../../utils/queryKeyFactory'
import { useAuthContext } from '../../../context/auth.context'
import { managerDetailsQuery } from './../../../hooks/dashboard.hooks'

function WorkStatus() {
  const {
    state: { locale },
  } = useLanguageContext()
  const {
    state: { authData },
  } = useAuthContext()
  const [managerDetailsParam, setManagerDetailsParam] = useState({
    manager_id: authData.asset_id,
  })

  const {
    data: managerDetailsData,
    refetch,
    isLoading: loadingManagerDetails,
  } = useQuery(
    getCollectionV2QueryKey.asset_param(managerDetailsParam),
    () => managerDetailsQuery(managerDetailsParam),
    {
      staleTime: 60 * 1000,
      refetchOnMount: true,
      enabled: !!managerDetailsParam.manager_id,
    }
  )
  if (loadingManagerDetails)
    return (
      <Center>
        <Spinner color="brand.900" />
      </Center>
    )

  return (
    <Box display="flex" justifyContent='flex-start'>
      <Box>
      <Text
        textTransform="uppercase"
        fontSize="16px"
        alignSelf="flex-end"
        fontWeight="bold"
      >
          {locale['Work Status']}
      </Text>
        <VStack alignItems="flex-start" spacing={1} pt={2} m='10px'>
          <Text my={0} fontSize="sm" textTransform="capitalize">
            {locale['Availability']} :
            <Text as="span" fontWeight="600" mr={1}>
              {' '}
              {locale['NA']}
            </Text>
          </Text>
          <Text my={0} fontSize="sm" textTransform="capitalize">
            {locale['Designated Location']} :
            <Text as="span" fontWeight="600" mr={1}>
              {managerDetailsData?.asset_work_location_address || locale['NA']}
            </Text>
          </Text>
          {/* <Text my={0} fontSize="sm" textTransform="capitalize">
          Live Location :
          <Text as="span" fontWeight="600" mr={1}>
            {managerDetailsData.asset_last_location_address || 'NA'}
          </Text>
        </Text> */}
          <Text my={0} fontSize="sm" textTransform="capitalize">
            {locale['Last known location']} :
            <Text as="span" fontWeight="600" mr={1}>
              {managerDetailsData?.asset_last_location_address || locale['NA']}
            </Text>
          </Text>
        </VStack>
      </Box>
    </Box>
  )
}

export default WorkStatus
