/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import Profile from '../../../assets/images/profile.png'
import {
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	Icon,
	IconButton,
	Image,
	Text,
	VStack,
	Input,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react'
import { HiOutlinePencilAlt, HiX } from 'react-icons/hi'

import { useAuthContext } from '../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'

import {
	getLocalAuthDetails,
	timeAgo,
	timelineDateFormatShort,
} from '../../../utils/common.util'
import CryptoJS from 'crypto-js'
import {
	useLoadAssetInlineCollection,
	useSetPassword,
} from '../../../hooks/asset.hooks'
import { loginConst } from './../../../utils/action.constant'
import { managerDetailsQuery } from './../../../hooks/dashboard.hooks'
import { getCurrentDateUTC } from './../../../utils/date.utils'
import { useQuery } from 'react-query'
import { getCollectionV2QueryKey } from './../../../utils/queryKeyFactory'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../../containers/repository/repo.utils'
import { GA_CATEGORY_NAVBAR } from './../../../containers/repository/repo.utils'
import './../style.css'
import EASScores from '../../EASScores'
import { loadAwsFile } from '../../../utils/aws.util'

export function ProfileDetails() {
	const {
		state: { authData },
		dispatch,
	} = useAuthContext()
	const { asset_arp_data } = authData
	const {
		state: { locale },
	} = useLanguageContext()
	const [managerDetailsParam, setManagerDetailsParam] = useState({
		manager_id: authData.asset_id,
	})
	const [isEdit, setEditable] = useState(false)
	const [username, setUsername] = useState('')
	const {
		data: managerDetailsData,
		refetch,
		isLoading: loadingManagerDetails,
	} = useQuery(
		getCollectionV2QueryKey.asset_param(managerDetailsParam),
		() => managerDetailsQuery(managerDetailsParam),
		{
			staleTime: 60 * 1000,
			refetchOnMount: true,
			enabled: !!managerDetailsParam.manager_id,
		}
	)
	const [profileImageData, setProfileImageData] = useState(null)
	const [password, setPassword] = useState('')
	const [show, setShow] = useState(false)
	const user = authData
	const { mutate: loadInlineData } = useLoadAssetInlineCollection()
	const { mutate } = useSetPassword()
	const resetPassword = () => {
		mutate(
			{
				...buildSavePasswordParams({ username, password }),
			},
			{
				onSuccess: async data => {
					updateUserDetails()
				},
			}
		)
	}

	const updateUserDetails = useCallback(data => {
		const payload = getLocalAuthDetails()
		loadInlineData(
			{ ...payload },
			{
				onSuccess: async res => {
					const userData = res.data.response.data
					dispatch({
						type: loginConst.SAVE_USER_AUTH,
						payload: userData,
					})
				},
			}
		)
	})
	const buildSavePasswordParams = ({ password, username }) => {
		const encPass = CryptoJS.AES.encrypt(
			password.toString().trim(),
			'lp-n5^+8M@62'
		).toString()
		return {
			...user,
			new_password: encPass,
			operating_asset_username: username,
		}
	}
	console.log(authData, 'authData')
	const loadUserDetails = useCallback(data => {
		const payload = getLocalAuthDetails()
		loadInlineData(
			{ ...payload },
			{
				onSuccess: async res => {
					let userData = res.data.response.data
					localStorage.setItem(
						'ORGANIZATION_ONHOLD',
						userData && userData.organization_flag_dashboard_onhold
					)
					localStorage.setItem(
						'ORGANIZATION_ENABLE_RESOURCE_FILTER',
						userData && userData.organization_flag_enable_resource_filter
					)
					if (
						!!userData &&
						!!userData.operating_asset_username &&
						!!userData.asset_email_password
					) {
						setUsername(userData.operating_asset_username)
						var bytes = CryptoJS.AES.decrypt(
							userData.asset_email_password,
							'lp-n5^+8M@62'
						)
						var originalText = bytes.toString(CryptoJS.enc.Utf8)
						setPassword(originalText)
					} else {
						setUsername(authData.operating_asset_email_id || '')
						setPassword('grene123')
					}
				},
				onError: async err => {
					console.log(err, 'err')
				},
			}
		)
	})

	useEffect(() => {
		loadUserDetails()
		setManagerDetailsParam({
			manager_id: authData.asset_id,
		})

		//authData as a dependency since on refresh, initially authData will be null
	}, [authData])

	useEffect(() => {
		setTimeout(() => {
			if (!!authData.asset_image_path) {
				loadAwsFile(authData.asset_image_path, d => {
					let data = 'data:' + d.type + ';base64,' + d.data
					setProfileImageData(data)
				})
			}
		}, 1000)
	}, [authData.asset_image_path, profileImageData])
	let editStatus =
		authData.organization_flag_email_integration_enabled === 1 ||
		authData.organization_flag_email_integration_enabled === 3
			? false
			: true
	return (
		<div>
			<Text
				textTransform='uppercase'
				fontSize='16px'
				alignSelf='flex-end'
				fontWeight='bold'
			>
				{locale['Profile Details']}
			</Text>

			<Box px={3} bg='white' m='10px'>
				<Box>
					<HStack alignItems='center'>
						<Image
							boxSize='130px'
							py={2}
							objectFit='contain'
							alt='company logo'
							fallbackSrc={Profile}
							src={profileImageData}
						/>

						<VStack
							justifyContent='flex-start'
							alignItems='flex-start'
							flex={1}
						>
							<Heading
								m={0}
								size='md'
								fontSize='lg'
								fontWeight='500'
								textTransform='capitalize'
								color='black'
							>
								{user.operating_asset_first_name}
							</Heading>
							<Text
								style={{ margin: 0 }}
								fontSize='md'
								textTransform='capitalize'
							>
								{user.asset_first_name}
							</Text>
							{user.asset_email_id && (
								<Text
									style={{ margin: 0 }}
									fontSize='sm'
									textTransform='capitalize'
								>
									{user.asset_email_id}
								</Text>
							)}
							{user.asset_phone_number && (
								<Text
									style={{ margin: 0 }}
									fontSize='sm'
									textTransform='capitalize'
								>
									+ {user.asset_phone_country_code} {user.asset_phone_number}
								</Text>
							)}
						</VStack>
					</HStack>
				</Box>
				<Divider />
				<VStack alignItems='flex-start' spacing={1} pt={2}>
					{!!user.workforce_name && (
						<Text my={0} fontSize='sm' textTransform='capitalize'>
							<Text my={0} as='span' fontWeight='600' mr={1}>
								{locale['Segment']} :
							</Text>
							{user.workforce_name}
						</Text>
					)}
					{user.asset_created_datetime ? (
						<Text>
							<Text as='span' className='w-500'>
								{locale['Created at']} :{' '}
							</Text>
							{timelineDateFormatShort(user.asset_created_datetime)}
						</Text>
					) : null}
					{user.account_name && (
						<Text my={0} fontSize='sm' textTransform='capitalize'>
							<Text my={0} as='span' fontWeight='600' mr={1}>
								{locale['Circle']} :
							</Text>
							{user.account_name}
						</Text>
					)}
					{managerDetailsData?.manager_operating_asset_first_name && (
						<Text my={0} fontSize='sm' textTransform='capitalize'>
							<Text my={0} as='span' fontWeight='600' mr={1}>
								{locale['Reporting Manager']} :
							</Text>
							{managerDetailsData?.manager_operating_asset_first_name}
						</Text>
					)}
					{managerDetailsData?.asset_joined_datetime && (
						<Text my={0} fontSize='sm' textTransform='capitalize'>
							<Text my={0} as='span' fontWeight='600' mr={1}>
								{locale['Joining Date']} :
							</Text>
							{getCurrentDateUTC(managerDetailsData?.asset_joined_datetime)}
						</Text>
					)}

					{!!managerDetailsData &&
					managerDetailsData.asset_last_seen_datetime ? (
						<Text my={0} fontSize='sm' textTransform='capitalize'>
							<Text as='span' fontWeight='600'>
								{locale['Last Seen']} :{' '}
							</Text>
							{timeAgo(managerDetailsData.asset_last_seen_datetime)}
						</Text>
					) : null}

					{!!user.asset_last_location_address ? (
						<Text my={0} fontSize='sm' textTransform='capitalize'>
							<Text as='span' fontWeight='600'>
								{locale['Last Location']} :
							</Text>{' '}
							{user.asset_last_location_address}
						</Text>
					) : null}
					{authData.organization_ai_bot_enabled === 1 && asset_arp_data && (
						<EASScores asset_arp_data={asset_arp_data} />
					)}
				</VStack>
			</Box>

			<VStack spacing={2} alignItems='flex-end' mt={2} bg='white' m='10px'>
				{/* ) : ( */}
				<HStack>
					<IconButton
						bg='white'
						aria-label='close'
						size='sm'
						borderRadius='md'
						boxShadow='md'
						isDisabled={editStatus}
						// disabled={!!Object.keys(currentField).length}
						_focus={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						icon={
							!isEdit ? (
								<Icon as={HiOutlinePencilAlt} w={4} h={4} />
							) : (
								<Icon as={HiX} w={4} h={4} />
							)
						}
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_NAVBAR,
							// 	action: 'Profile',
							// 	label: 'Toggle Editable',
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Profile',
							// 	buttonName: 'Toggle Edit',
							// })
							setEditable(e => !e)
							// onEdit(field);
						}}
					/>
				</HStack>
				{/* )} */}
				<Input value={user.asset_email_id || ''} disabled placeholder='Email' />
				<Input
					onChange={e => setUsername(e.target.value)}
					value={username}
					placeholder={locale['User Name']}
					isDisabled={!isEdit}
				/>
				<InputGroup size='md'>
					<Input
						pr='4.5rem'
						type={show ? 'text' : 'password'}
						placeholder={locale['Enter password']}
						value={password}
						onChange={e => setPassword(e.target.value)}
						isDisabled={!isEdit}
					/>
					<InputRightElement width='4.5rem'>
						<Button
							h='1.75rem'
							size='sm'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_NAVBAR,
								// 	action: 'Profile',
								// 	label: locale['Toggle Show Password'],
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Profile',
								// 	buttonName: show ? 'Hide' : 'Show',
								// })
								setShow(e => !e)
							}}
							isDisabled={!isEdit}
						>
							{show ? locale['Hide'] : locale['Show']}
						</Button>
					</InputRightElement>
				</InputGroup>
			</VStack>
			{authData.organization_flag_email_integration_enabled === 1 && (
				<Text my={0} mt={2} fontSize='xs' textTransform='capitalize'>
					<Text as='span' fontWeight='600' mr={1}>
						{locale['Note']} :
					</Text>
					<Text my={0} as='span' fontWeight='500' mr={1}>
						{
							locale[
								'Please ensure username and password are set as per oulook configuration to ensure email delivery.'
							]
						}
					</Text>
				</Text>
			)}

			<Divider pt={2} />
			<Button
				mt={4}
				isDisabled={!isEdit}
				colorScheme={localStorage.getItem('color')}
				bg={localStorage.getItem('color')}
				onClick={() => {
					// sectionDetailedTrack({
					// 	category: GA_CATEGORY_NAVBAR,
					// 	action: 'Profile',
					// 	label: locale['Reset Password'],
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Profile',
					// 	buttonName: 'Submit',
					// })
					resetPassword()
				}}
				type='submit'
				float='right'
				// isLoading={isLoading}
			>
				{locale['Submit']}
			</Button>
		</div>
	)
}
