import {
  Divider,
  HStack,
  Modal,
  Icon,
  VStack,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { BiDownload } from 'react-icons/bi'
import { sectionDetailedTrack } from '../../../containers/repository/repo.utils'
import { useAuthContext } from '../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'
import { downloadAwsFile } from '../../../utils/aws.util'
import { GA_CATEGORY_NAVBAR } from './../../../containers/repository/repo.utils'

const DownloadTemplates = ({ isOpen, onClose }) => {
  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()
  const { organization_file_templates } = authData

  if (!organization_file_templates) {
    return null
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text
            flex={1}
            fontWeight="400"
            fontSize="md"
            color="black"
            textTransform="capitalize"
          >
            {locale['Download Templates']}
          </Text>
        </ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            {organization_file_templates.map(fileItem => {
              return (
                <Fragment key={fileItem.file_name}>
                  <HStack
                    key={fileItem.file_name}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <VStack alignItems="flex-start" flex={1}>
                      <Text
                        textAlign="center"
                        fontSize="md"
                      >{`${fileItem.file_name}`}</Text>
                      <Text
                        textAlign="center"
                        fontSize="sm"
                      >{`${fileItem.file_type}`}</Text>
                    </VStack>
                    <VStack>
                      <IconButton
                        onClick={() => {
                          sectionDetailedTrack({
                            category: GA_CATEGORY_NAVBAR,
                            action: 'Download Templates',
                            label: 'Download AWS File',
                          })
                          console.log('fileItem.file_url', fileItem.file_url)
                          downloadAwsFile(fileItem.file_url)
                        }}
                        my={2}
                        icon={<Icon as={BiDownload} h={5} w={5} />}
                        color={localStorage.getItem('color')}
                        _focus={{
                          color: localStorage.getItem('color'),
                        }}
                        _hover={{
                          color: 'white',
                          bg: localStorage.getItem('color'),
                        }}
                        _active={{
                          color: 'white',
                          bg: localStorage.getItem('color'),
                        }}
                      />
                    </VStack>
                  </HStack>
                  <Divider mb={2} />
                </Fragment>
              )
            })}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DownloadTemplates
